import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminAnalyticsService {
  constructor(private appService: AppService) {}

  getLgaCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA: string;
    PaymentGateway: string;
    PaymentChannel: string;
    TaxPayerCategory: string;
    EngineCode: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getLgaAnalytics}`,
      params
    );
  }

  getPercentagePaidUnpaidCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      APIConstant.adminDashboard.getPercentagePaidUnpaidTimeSeries,
      params
    );
  }

  getTotalGeneratedRevenueCard(params: { yr: number }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalGeneratedRevenueTimeSeries}`,
      params
    );
  }

  getPercentageInvoicePaidByMonthCard(params: {
    mois: number;
    yr: number;
    MinDate?: string;
    MaxDate?: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByMonth}`,
      params
    );
  }

  getPercentageRevenueCollectedByTaxPayerCategoryCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageRevenueCollectedByTaxPayerCategory}`,
      params
    );
  }

  getTotalInvoicePaidByBankCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalInvoicePaidByBank}`,
      params
    );
  }

  getPercentageInvoicePaidByTop5MvaCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByTop5Mva}`,
      params
    );
  }

  getPercentageInvoicePaidByBottom5MvaCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByBottom5Mva}`,
      params
    );
  }

  getPercentageInvoicePaidByTop3MdaCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByTop3Mda}`,
      params
    );
  }

  getPercentageInvoicePaidByBottom3MdaCard(params: {
    MinDate: string;
    MaxDate: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getPercentageInvoicePaidByBottom3Mda}`,
      params
    );
  }

  getTotalInvoicePaidByGatewayCard(params: {
    yr: number;
    MinDate?: string;
    MaxDate?: string;
    MDA?: string;
    PaymentGateway?: string;
    PaymentChannel?: string;
    TaxPayerCategory?: string;
    EngineCode?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getTotalInvoicePaidByGatewayTimeSeries}`,
      params
    );
  }
}
