import { inject, Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { ObjectLiteral } from '../models/ObjectLiteral';
import { lastValueFrom } from 'rxjs';
import { LookupService } from './lookup.service';

@Injectable({
  providedIn: 'root',
})
export class AdminDashboardService {
  lookupService = inject(LookupService);
  appService = inject(AppService);

  getCards(params: {
    MinDate: string;
    MaxDate: string;
    PaymentChannel: string;
    TaxPayerCategory: string;
    MDA: string;
    PaymentGateway: string;
    EngineCode: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminDashboard.getDashboardStat}`,
      params
    );
  }

  getAnalyticsData(
    params:
      | ObjectLiteral
      | {
          MinDate: string;
          MaxDate: string;
          PaymentChannel: string;
          TaxPayerCategory: string;
          MDA: string;
          PaymentGateway: string;
          EngineCode: string;
        }
  ) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getLgaAnalytics}`,
        params
      )
    );
  }

  async analyticsFilterList() {
    const promiseArrTwo = [
      this.lookupService.getMda(),
      this.lookupService.getEngineCodes(),
      this.lookupService.getPaymentChannels(),
      this.lookupService.getPaymentGateways(),
      this.lookupService.getTaxPayerCategoriesPromise(),
    ];

    const [
      mdaLookup,
      engineCodeLookup,
      channelLookup,
      paymentGatewayLookup,
      tpcLookup,
    ] = await Promise.all(promiseArrTwo);

    return {
      channelList: channelLookup,
      mdaList: mdaLookup,
      tpcList: tpcLookup,
      gatewayList: paymentGatewayLookup,
      engineCodeList: engineCodeLookup,
    };
  }

  async getTop3TaxOffices(
    params:
      | ObjectLiteral
      | {
          MinDate: string;
          MaxDate: string;
          PaymentChannel: string;
          TaxPayerCategory: string;
          MDA: string;
          PaymentGateway: string;
          EngineCode: string;
        }
  ): Promise<any[]> {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getTop3TaxOffices}`,
        params
      )
    );
  }

  async getBottom3TaxOffices(
    params:
      | ObjectLiteral
      | {
          MinDate: string;
          MaxDate: string;
          PaymentChannel: string;
          TaxPayerCategory: string;
          MDA: string;
          PaymentGateway: string;
          EngineCode: string;
        }
  ): Promise<any[]> {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getBottom3TaxOffices}`,
        params
      )
    );
  }

  async getTimeSeriesTSPDashboard(
    params:
      | ObjectLiteral
      | {
          yr: string;
        }
  ): Promise<any[]> {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminDashboard.getTimeSeriesTSPDashboard}`,
        params
      )
    );
  }
}
