import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminInvoiceReportService {
  constructor(private appService: AppService) {}

  getAll(params: {
    pageNumber: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    search?: string;
    MinDate?: string;
    MaxDate?: string;
    RHId?: string;
    Status?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getAll}`,
      params
    );
  }

  getAllForDownload(params: {
    pageNumber?: number;
    pageSize?: number;
    MinDate?: string;
    MaxDate?: string;
  }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminInvoiceReport.getAllForDownload}`,
        params
      )
    );
  }

  getOne(params: { id: string }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getOne}`,
      params
    );
  }

  getCards(params: {
    search?: string;
    MinDate: string;
    MaxDate: string;
    RHId?: string;
    Status?: string;
  }) {
    return this.appService.get(
      `${APIConstant.adminInvoiceReport.getAnalytics}`,
      params
    );
  }

  async getAllTspCards(params: { MinDate?: string; MaxDate?: string }) {
    const promise = [
      this.getTspCards(params),
      this.getTspTopMdaCard(params),
      this.getTspTopRhCard(params),
      this.getTspTopTaxOfficeCard(params),
    ];

    const [tspCards, topMda, topRh, topTaxOffice] = await Promise.all(promise);

    return {
      tspCards,
      topMda,
      topRh,
      topTaxOffice,
    };
  }

  getTspTopMdaCard(params: { MinDate?: string; MaxDate?: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminInvoiceReport.getTspTopMdaCard}`,
        params
      )
    );
  }

  getTspTopRhCard(params: { MinDate?: string; MaxDate?: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminInvoiceReport.getTspTopRhCard}`,
        params
      )
    );
  }

  getTspTopTaxOfficeCard(params: { MinDate?: string; MaxDate?: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminInvoiceReport.getTspTopTaxOfficeCard}`,
        params
      )
    );
  }

  getTspCards(params: { MinDate?: string; MaxDate?: string }) {
    return lastValueFrom(
      this.appService.get(
        `${APIConstant.adminInvoiceReport.getTspCards}`,
        params
      )
    );
  }
}
